import * as Bowser from 'bowser';
import createMentionPlugin from 'cus-draft-js-mention-plugin';
import dayjs from 'dayjs';
import { convertToRaw } from 'draft-js';
import {
  CLASS_CUS_POINT_DEFAULT,
  REGEX_HASHTAG,
  REGEX_MENTION,
  REGEX_POINT,
  specialBreadcrumb,
  TIMEZONE
} from 'src/common/constants';
import LazyLoadImage from 'src/components/LazyLoadingImage/lazyLoadImage';
import { IBreadcrumb } from 'src/interface/IBreadcrumb';
import { IinforProject } from 'src/interface/IHeatmapState';
import { IdataMention } from 'src/interface/IStatusState';
import { ParentOptionCMS, User } from 'src/interface/ITreeNode';
import { imgs } from 'src/static';

export const genId = (): string => '_' + Math.random().toString(36).substr(2, 9);

export const handleRecent = (ids = []): void => {
  const recent = localStorage.getItem('recent') || '[]';
  const recents = ids.concat(JSON.parse(recent));
  const uniqRecents = Array.from(new Set(recents));
  localStorage.setItem('recent', JSON.stringify(uniqRecents));
};

// eslint-disable-next-line
export const findProjectRecents = (projects: IinforProject[]): any => {
  if (!projects || projects.length < 1) return [];
  try {
    const recents = JSON.parse(localStorage.getItem('recent') || '[]');
    const recentPrj: Array<IinforProject> = recents.map((id: number) => {
      const project = projects.find((project) => project.id === id);
      return project;
    });
    return recentPrj;
  } catch (error) {
    // JSON.parse maybe throw error
    return [];
  }
};

export const getUserId = (): number => {
  if (typeof localStorage !== 'undefined') {
    const user_id = localStorage?.getItem('user_id') || '';
    const id = parseInt(user_id);
    return id;
  }
  return 0;
};

export const handleBreakline = (text: string): string => {
  if (text) {
    text = text.trim();
    return text.replace(/(\r\n|\n|\r)/gm, '<br />');
  } else return '';
};

export const handleDateTimeFromTo = (timeStart: string, timeEnd: string): string => {
  const formatTime = 'hh:mmA MMMM DD, YYYY';
  return timeStart && timeEnd
    ? dayjs(timeStart).format(formatTime) + ' - ' + dayjs(timeEnd).format(formatTime)
    : 'Unknown time';
};

export const handleDateTimeCreate = (time: string): string => {
  const formatTimeFrom = 'hh:mmA MMMM DD, YYYY';
  return time ? dayjs(time).format(formatTimeFrom) : '';
};

export const getHeightChart = (isHeatmap: boolean, idElement: string): number => {
  const chartContainer = document.getElementById(idElement);
  if (!chartContainer) {
    return 600;
  }
  const rectChart = chartContainer.getBoundingClientRect();
  const offsetTop = rectChart.top;
  const padding = isHeatmap ? 150 : 25;
  const height = window.innerHeight - offsetTop - padding;
  return height < 400 ? 400 : height;
};

export const getDevicesData = (): any => {
  const bowser = Bowser.parse(window.navigator.userAgent);
  const tag = process.env.REACT_APP_VERSION;
  const version = tag && tag.match(/release-(?:dev|pro|staging)-([^=]+)/);
  const navigator_info = window.navigator;
  let uid: any = navigator_info.mimeTypes.length;
  uid += navigator_info.userAgent.replace(/\D+/g, '');
  uid += navigator_info.plugins.length;
  const params = {
    uid: uid,
    os_type: 'Web',
    os_version: `${bowser.browser.name} | ${bowser.os.version}`,
    app_version: version ? version[1] : 'development',
    fcm_token: localStorage.getItem('firebase_token')
  };
  return params;
};
export const handlePushValueRecentSearch = (newValue: string, nameRecent: string) => {
  const recentList = JSON.parse(localStorage.getItem(nameRecent) || '[]');
  if (recentList.indexOf(newValue) < 0 && newValue) {
    localStorage.setItem(nameRecent, JSON.stringify([newValue, ...recentList]));
  }
};
export const handlePopValueRecentSearch = (keyValue: string, nameRecent: string) => {
  const recentList = JSON.parse(localStorage.getItem(nameRecent) || '[]');
  const newList = recentList.filter((item) => item !== keyValue);
  localStorage.setItem(nameRecent, JSON.stringify(newList));
};
export const getBase64 = async (img: any) => {
  let src = '';
  const reader = new FileReader();
  src = await new Promise((resolve: any) => {
    reader.readAsDataURL(img);
    reader.onload = () => resolve(reader.result);
  });
  return src;
};
export const getToken = () => {
  const token = localStorage?.getItem('token') || '';
  return token;
};
export const getTabsById = (id: number) => {
  const data = [
    {
      id: 2,
      name: 'SEMINAR',
      taggings_count: 0,
      kind: 1,
      color: '#F2994A'
    },
    {
      id: 3,
      name: 'EVENT',
      taggings_count: 0,
      kind: 1,
      color: '#6FCF97'
    },
    {
      id: 4,
      name: 'TOPIC PERSONAL',
      taggings_count: 0,
      kind: 1,
      color: '#885AF8'
    }
  ];
  for (let index = 0; index < data.length; index++) {
    if (data[index].id === id) {
      return data[index];
    }
  }
};
export const filterOptionOrganizer = (
  objFilter: { [key: string]: any },
  valueFilter: string
): boolean => {
  if (
    objFilter &&
    (objFilter.id.toString() === valueFilter.toString() ||
      objFilter.name.toLowerCase().indexOf(valueFilter.toLowerCase()) > -1)
  ) {
    return true;
  }
  return false;
};

export function capFirstLetters(text: string): string {
  let capFirstLetter = text ? text.toLowerCase() : '';

  if (text.indexOf(' ') > 1) {
    let texts = capFirstLetter ? capFirstLetter.split(' ') : [];

    texts = texts.map(
      (element: string) => element && `${element.charAt(0).toUpperCase()}${element.substring(1)}`
    );
    capFirstLetter = texts.join(' ');
  } else {
    capFirstLetter = `${capFirstLetter.charAt(0).toUpperCase()}${capFirstLetter.substring(1)}`;
  }

  return capFirstLetter;
}

export const getBreadcrumbObj = (path: string): Array<IBreadcrumb> => {
  if (specialBreadcrumb[path]) return specialBreadcrumb[path];

  const pathBreadcrumb = path.startsWith('/') ? path.substr(1) : path;
  const pathNames = pathBreadcrumb.split('/') || [];
  const breadcrumbs: Array<IBreadcrumb> = [];
  let url = '/';

  for (let i = 0; i < pathNames.length; i++) {
    url = pathNames[i] ? `${url}${pathNames[i]}/` : url;

    breadcrumbs.push({
      title: pathNames[i].replace(/-|_/g, ' '),
      url: url.endsWith('/') ? url.slice(0, -1) : url
    });
  }
  breadcrumbs[0].title = breadcrumbs[0].title === 'activity' ? 'activities' : breadcrumbs[0].title;

  if (breadcrumbs.length > 1 && breadcrumbs[1].title === 'news') {
    breadcrumbs[1].title = 'blog';
  }

  return breadcrumbs;
};

// eslint-disable-next-line
export const createPlugin = (
  mentionPrefix: string,
  style: { [key: string]: string },
  hasSuggesPos: boolean | undefined,
  mentionRegExp = ''
) => {
  // eslint-disable-next-line
  const mentionObj: any = {
    mentionTrigger: mentionPrefix,
    mentionPrefix: mentionPrefix === '#' || mentionPrefix === ':' ? '' : mentionPrefix,
    entityMutability: 'IMMUTABLE',
    theme: style
  };

  if (hasSuggesPos) {
    mentionObj.positionSuggestions = () => ({
      top: '0',
      transform: 'scale(1) translateY(-100%)',
      transition: 'all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)'
    });
  }

  if (mentionRegExp) {
    mentionObj.mentionRegExp = mentionRegExp;
  }

  return createMentionPlugin(mentionObj);
};

// eslint-disable-next-line
export const isInViewport = (element: any): boolean => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

// eslint-disable-next-line
export const validateContent = (currenContentBlock: any): string => {
  const arrayBlockText = convertToRaw(currenContentBlock.getCurrentContent()).blocks.map(
    (item) => item.text
  );
  const contentState = arrayBlockText.filter(
    (item: string, index: number) => arrayBlockText.indexOf(item) === index
  );
  let content = contentState.reduce((a, b) => a + ' ' + b).replace(/  +/g, ' ');

  content =
    content !== '' && content !== ' '
      ? contentState.reduce((a, b) => a + ' \n' + b).replace(/  +/g, ' ')
      : content;
  return content;
};

export const breakLine = (text: string): string => {
  return text ? text.replace(/(?:\r\n|\r|\n)/g, ' <br />') : text;
};

export const calcPreferredSizeOfPreviewModal = (idElement: string): string => {
  const imageEl = document.getElementById(idElement);
  if (imageEl) {
    if (imageEl?.offsetHeight > imageEl?.offsetWidth) {
      const percentOfPreferredWidth = (imageEl?.offsetWidth / window.innerWidth) * 100;
      return percentOfPreferredWidth + '%';
    }
    return '50%';
  }
  return '50%';
};

export const standardizedEventTime = (date: string): string => {
  const dateCurrent =
    dayjs(new Date()).format('YYYY-MM-DD') + 'T' + dayjs(new Date()).format('HH:mm:ss') + TIMEZONE;
  const formattedDate =
    dayjs(date).format('YYYY-MM-DD') + 'T' + dayjs(date).format('HH:mm:ss') + TIMEZONE;
  if (formattedDate === undefined) {
    return dateCurrent;
  }
  return formattedDate;
};
export const exportDateFromFullDate = (fullDate?: string): string => {
  if (fullDate) {
    return dayjs(fullDate).format('YYYY-MM-DD') + 'T';
  }
  return dayjs(new Date()).format('YYYY-MM-DD') + 'T';
};
export const exportTimeFromFullDate = (fullDate?: string): string => {
  if (fullDate) {
    return dayjs(fullDate).format('HH:mm:ss') + TIMEZONE;
  }
  return dayjs(new Date()).format('HH:mm:ss') + TIMEZONE;
};

export const getArrayHashTagFromContent = (content: string): string[] => {
  const specialSymbolHTML = /&[#A-Za-z0-9]+;/g;
  const ruleHashtag = /(\#[a-zA-Z_0-9]+\b)/; // eslint-disable-line
  const hexadecimalColorCode = /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/g;

  content = content
    ? content.replaceAll(specialSymbolHTML, '').replaceAll(hexadecimalColorCode, '')
    : content;

  const listHashTag = content.split(ruleHashtag).map((chunk: string) => {
    return chunk.match(ruleHashtag) ? chunk : null;
  });
  const arrayHashtag: string[] = [];
  listHashTag.forEach((element: string | null) => {
    if (element) {
      arrayHashtag.push(element);
    }
  });
  return [...new Set(arrayHashtag)];
};

export const getSwitchButtonValue = (value: string): boolean => {
  let switchButtonStatus = false;
  if (localStorage && localStorage.getItem(value)) {
    const switchFromLcs = localStorage.getItem(value);
    if (switchFromLcs === 'true') {
      switchButtonStatus = true;
    }
  }
  return switchButtonStatus;
};

// export const getParamSearchURL = (keyword: string) => {
//   const paramSearch = querySearch.parse(keyword);
//   return paramSearch.search ? paramSearch.search.toString().trim() : null;
// };

export const defaultColorById = (id: number): string => {
  const blue = Math.floor(id % 16).toString(16);
  const green = Math.floor((id ^ 2) % 16).toString(16);
  const red = Math.floor((id ^ 3) % 15).toString(16);
  return '#' + red + red + green + green + blue + blue;
};

// eslint-disable-next-line
export const lazyLoadingImages = (src: string, imgRef: any): string => {
  return LazyLoadImage(src, imgs.PlaceHolderImage, imgRef).imgSrc;
};

// eslint-disable-next-line
export const lazyLoadingCss = (src: string, imgRef: any): string => {
  return LazyLoadImage(src, imgs.PlaceHolderImage, imgRef).css;
};

export const isYouTubeUrl = (text: string): boolean => {
  if (text !== undefined && text !== '') {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
    const match = text.match(regExp);
    return match && match[2].length === 11;
  }

  return false;
};

export const parseURL = (text: string): RegExpMatchArray | [] => {
  if (text !== undefined && text !== '') {
    const regExp = /([a-z]{1,2}tps?):\/\/((?:(?!(?:\/|#|\?|&)).)+)(?:(\/(?:(?:(?:(?!(?:#|\?|&)).)+\/))?))?(?:((?:(?!(?:\.|$|\?|#)).)+))?(?:(\.(?:(?!(?:\?|$|#)).)+))?(?:(\?(?:(?!(?:$|#)).)+))?(?:(#.+))?/g;
    const match = text.match(regExp);
    return match || [];
  }

  return [];
};

export const getYouTubeId = (url: string): string => {
  const [a, , b] = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

  if (b !== undefined) {
    return b.split(/[^0-9a-z_-]/i)[0];
  }

  return a;
};

export const getSelectValue = (selector: string, defaultValue: string): string => {
  let value: string = defaultValue;

  if (localStorage) {
    const v = localStorage.getItem(selector);
    if (v) {
      value = v;
    }
  }

  return value;
};

export const dataURLtoFile = (dataurl: string, filename: string): File => {
  const arr: string[] = dataurl.split(',');
  const mime = (arr[0].match(/:(.*?);/) as RegExpMatchArray)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const getPageTitle = (title?: string): string => {
  const suffix = 'Offwork - Executionlab';
  if (title) {
    return title + ' - ' + suffix;
  }

  return suffix;
};

export const capitalizeFirstLetter = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getFormData = (data: string[]): FormData => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(`user[${key}]`, data[key]));
  return formData;
};

export const handleSetDefaultColorPoint = () => {
  document.querySelectorAll('.cus-point').forEach((e) => {
    if (e.textContent) {
      if (!REGEX_POINT.test(e.textContent)) {
        e.classList.add(CLASS_CUS_POINT_DEFAULT);
      } else if (e.classList.contains(CLASS_CUS_POINT_DEFAULT)) {
        e.classList.remove(CLASS_CUS_POINT_DEFAULT);
      }
    }
  });
};

export const handleSetDefaultColorMention = (mentions: IdataMention[]) => {
  document.querySelectorAll('.cus-point, .cus-mention').forEach((e) => {
    const text = e.textContent;

    if (text) {
      if (REGEX_POINT.test(text)) {
        e.classList.remove(CLASS_CUS_POINT_DEFAULT);
      } else if (
        REGEX_MENTION.test(text) &&
        mentions.find((mention) => text.replace('@', '').trim() === mention.name.trim())
      ) {
        e.classList.remove(CLASS_CUS_POINT_DEFAULT);
      } else if (!REGEX_HASHTAG.test(text)) {
        e.classList.add(CLASS_CUS_POINT_DEFAULT);
      } else {
        e.classList.remove(CLASS_CUS_POINT_DEFAULT);
      }
    }
  });
};

export const formatSelectValues = (data: User[]) => {
  const newArr = data.map((e) => {
    return {
      value: e.id,
      label: e.full_name
    };
  });

  return newArr;
};

export const formatSelectValuesCms = (data: User[]) => {
  const newArr = data.map((e) => {
    return {
      value: e.id.toString(),
      label: e.full_name
    };
  });

  return newArr;
};

export const formatSelectParentValuesCms = (
  dataFull: ParentOptionCMS[],
  dataChildren: ParentOptionCMS[]
) => {
  const newData = dataFull.filter((item) => !dataChildren.some((item2) => item.id === item2.id));
  const newArr = newData.map((e) => {
    return {
      value: e.id,
      label: e.name
    };
  });

  return newArr;
};
