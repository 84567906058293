import APP_ROUTES from './appRoutes';

interface StringName {
  [key: string]: string;
}
export const PathNameString: StringName = {
  '/': '',
  '/heatmap': 'Heatmap',
  '/activity/announcements': 'Activity',
  '/profile': 'Profile',
  '/reward': 'Reward',
  '/activity/news/detail': 'Activity / News',
  '/activity/event/detail': 'Activity / Event'
};

export const specialBreadcrumb = {
  '/activity/announcements': [
    {
      title: 'Activities',
      url: APP_ROUTES.ACTIVITY_ANNOUNCEMENTS
    }
  ],
  '/': [
    {
      title: 'Dashboard',
      url: APP_ROUTES.HOME
    }
  ]
};
export const FORMAT_MONTH_YEAR = 'MMMM YYYY';
export const FORMAT_DAY_MONTH_YEAR = 'ddd, MMMM DD, YYYY';

export const SESSION_STORAGE_ACTIVE_MONTH = 'ActiveMonth';
export const SESSION_STORAGE_TIME_FROM_WEEK = 'TimeFromWeek';
export const SESSION_STORAGE_TIME_FROM_MONTH = 'TimeFromMonth';

export const BOOK_MARK = 'bookmark';
export const TIMEZONE = '+07:00';
export const CARD = {
  STATUS: 'status',
  NEWS: 'news',
  ANNOUNCEMENT: 'announcements'
};
export enum STATUS {
  DETAIL = 'StatusDetail',
  LIST = 'StatusList',
  MODAL = 'StatusModal'
}
export const DETAIL = 'detail';
export const NEWS_DRAFT_TITLE = 'NEWS_DRAFT_TITLE';
export const NEWS_DRAFT_CONTENT = 'NEWS_DRAFT_CONTENT';

export const ANNOUNCEMENT_DRAFT_TITLE = 'ANNOUNCEMENT_DRAFT_TITLE';
export const ANNOUNCEMENT_DRAFT_CONTENT = 'ANNOUNCEMENT_DRAFT_CONTENT';

export const imageTypes = {
  giphy: 'GIPHY',
  image: 'IMAGE'
};

export const DEFAULT_LOCATION = 'HCM';

export const SELECT_STATUS_ORDER = 'statusOrder';
export const SWITCH_BUTTON_FILTER_UNREAD = 'switchButtonFilterUnread';
export const KEY_SHOWED_WALKTOUR = 'KEY_SHOWED_WALKTOUR';
export const COLLAPSE_STATUS_SETTING = 'COLLAPSE_STATUS_SETTING';

export const STATUS_URL = 'status';

export enum STATUS_ORDER {
  RECENT = 'recent',
  SCORE = 'score',
  INTERACTION = 'interaction'
}

export const EMIT_TYPING_EVENT_DELAY = 4000;

export interface IActivitiPages {
  name: string;
  path: string;
  hasNoti?: boolean;
}
export type IMenuCategories = IActivitiPages;

export const activitiPages: IActivitiPages[] = [
  {
    name: 'Announcements',
    path: APP_ROUTES.ACTIVITY_ANNOUNCEMENTS,
    hasNoti: true
  },
  {
    name: 'Blog',
    path: APP_ROUTES.ACTIVITY_NEWS
  },
  {
    name: 'Status',
    path: APP_ROUTES.ACTIVITY_STATUS
  },
  {
    name: 'Point Reward',
    path: APP_ROUTES.ACTIVITY_POINT_REWARD
  }
];

export const PAGE_TITLE = {
  SIGN_IN: 'Sign in',
  REWARD: 'Reward',
  RESET_PASSWORD: 'Reset Password',
  PROFILE: 'Profile',
  ACCOUNT: 'Account',
  HEATMAP: 'Heatmap',
  FORGOT_PASSWORD: 'Forgot Password',
  ACTIVITY: 'Activity',
  STATUS: 'Status',
  POLL: 'Poll',
  POINT_REWARD: 'Point Reward',
  BLOG: 'Blog',
  ANNOUNCEMENTS: 'Announcements',
  ACTIVITY_HISTORY: 'Activity History',
  PORTAL: 'Portal',
  ORG_CHART: 'Org Chart',
  QA: 'Q&A'
};

export const REGEX_POINT = /(\s|^)\+(5|10|15|20)$/;

export const REGEX_MENTION = /(\s|^)@(\w|\.)+$/;

export const REGEX_CHAIN_MENTION = '(\\w|\\.)*';

export const REGEX_HASHTAG = /(\s|^)#\w+$/;

export const CLASS_CUS_POINT_DEFAULT = 'cus-point-default';

export const CHANNEL = {
  POST: 'PostChannel',
  COMMENT: 'CommentChannel',
  APPEARANCE: 'AppearanceChannel',
  USER: 'UserChannel',
  TYPING: 'TypingChannel'
};

export const CHATGPT_BASE_URL = process.env.NEXT_PUBLIC_CHATGPT_BASE_URL || '';

export const CONTAINER_MARGIN = 0.09;

// This variable has been unified with the backend
export const DEFAULT_MODEL = {
  name: 'GPT 3.5',
  model: 'openai_gpt_3_5_turbo'
};
